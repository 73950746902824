import * as React from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

const calculateYearsAndMonths = (startDate) => {
  const start = new Date(startDate);
  const now = new Date();
  const years = now.getFullYear() - start.getFullYear();
  const months = now.getMonth() - start.getMonth();
  const totalMonths = years * 12 + months;
  const displayYears = Math.floor(totalMonths / 12);
  const displayMonths = totalMonths % 12;
  return `${displayYears} years and ${displayMonths} months`;
};

const MemberContent = ({ member }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    name,
    role,
    start_date,
    fav_book,
    fav_color_name,
    fav_color_hex,
    fav_country,
    fav_movie,
    fav_music,
    fav_partofjob,
    linkedin,
  } = member || {};

  const InfoItem = ({
    title,
    value,
    color,
    fontWeight = 700,
    valueFontWeight = 100,
  }) => (
    <Grid container sx={{ display: "flex", alignItems: "right" }}>
      <Grid item xs={9}>
        <Typography variant="h6" sx={{ mt: "1rem", fontWeight }}>
          {title}
        </Typography>
        <Typography variant="p" sx={{ fontWeight: valueFontWeight }}>
          {value}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        justifyContent={mobile ? "left" : "right"}
        alignItems="center"
      >
        {color && (
          <Box
            sx={{
              display: "inline-block",
              width: 80,
              height: 80,
              backgroundColor: color ? color : "#fff",
              borderRadius: "0",
              m: {
                xs: "1rem 0 0 0",
                md: "0 2rem 0 0",
              },
            }}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Typography
        variant="h3"
        sx={{
          color: (theme) => theme.palette.white,
          fontWeight: 700,
        }}
      >
        {name}
      </Typography>
      <Typography variant="p" sx={{ color: (theme) => theme.palette.yellow }}>
        {role}
      </Typography>
      <Box sx={{ color: (theme) => theme.palette.white, maxWidth: "600px" }}>
        <InfoItem
          title="Years at DM:"
          value={calculateYearsAndMonths(start_date)}
        />
        <InfoItem title="Favourite Book:" value={fav_book} />
        <InfoItem title="Favourite Film:" value={fav_movie} />
        <InfoItem title="Favourite Country Visited:" value={fav_country} />
        <InfoItem
          title="Favourite Music Artist, Album, or Genre:"
          value={fav_music}
        />
        <InfoItem title="Favourite Part of Your Job:" value={fav_partofjob} />
        <InfoItem
          title="Favourite Farrow & Ball Colour:"
          value={fav_color_name}
          color={fav_color_hex}
        />
      </Box>
    </>
  );
};

export default function AboutModal({ member, open, setOpen }) {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const theme = useTheme();

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullScreen={mobile ? true : false}
        maxWidth="md"
        sx={{
          zIndex: 9999,
          //margin: mobile ? "1em" : "0",
        }}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.blue,
            boxShadow: "none",
          },
        }}
      >
        <DialogContent
          sx={{
            maxWidth: mobile ? "100vw" : "60vw",
            overflowY: "scroll",
            p: mobile ? "1rem" : "2rem",
            overflowY: mobile ? "auto" : "unset",
          }}
          mobile={mobile}
        >
          <IconButton
            aria-label="Close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              top: mobile ? 20 : 38,
              right: mobile ? 20 : 38,
              zIndex: 2000000,
              color: "#fff",
              background: "#01153c9e",
              "&:hover": {
                background: "#01153c!important",
              },
              padding: "5px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container>
            {mobile ? (
              <>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      m: mobile ? "0 0 1em 0 " : "3rem 0 2rem 0",
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND +
                        member?.photo?.data?.attributes?.url
                      }
                      alt={member?.photo?.data?.attributes?.alternativeText}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={10} justifyContent="center">
                    <Box sx={{ mb: "5rem" }}>
                      <MemberContent member={member} />
                    </Box>
                  </Grid>
                  {Boolean(member.linkedin) && (
                    <Grid item xs={12} md={2} textAlign="right">
                      <IconButton
                        aria-label="LinkedIn"
                        component="a"
                        href={member.linkedin}
                        target="_blank"
                      >
                        <LinkedInIcon fontSize="large" sx={{ color: "#fff" }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ position: "relative" }}>
                    {Boolean(member.linkedin) && (
                      <IconButton
                        aria-label="LinkedIn"
                        component="a"
                        href={member.linkedin}
                        target="_blank"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          zIndex: 1000000,
                        }}
                      >
                        <LinkedInIcon fontSize="large" sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                    <MemberContent member={member} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ position: "relative", height: "100%" }}>
                    <img
                      src={
                        process.env.REACT_APP_BACKEND +
                        member?.photo?.data?.attributes?.url
                      }
                      alt={member?.photo?.data?.attributes?.alternativeText}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
