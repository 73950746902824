import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MetaContext } from "../context/MetaContext";
import parse from 'html-react-parser';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

function Contact() {

	const meta=useContext(MetaContext);

    //Set html background color
    useEffect(()=>{
        document.body.style.backgroundColor='#01153C'
    },[]);
    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const { data: pageData } = useQuery(({
		queryKey: ['page','contact'],//must match the menu slug
		queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/contact-page?populate=deep`)
	}));

	useEffect(()=>{
		//console.log(pageData)
		if(pageData){
            meta.setTitle(pageData.data.data.attributes.seo.metaTitle);
			meta.setDescription(pageData.data.data.attributes.seo.metaDescription);
		}
	},[pageData]);

    const [success, setSuccess]=useState(false);

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#fff'}}));
	},[]);

    return (
        <Box
            className='dm-section'
            data-color='#fff'
            sx={{
                backgroundColor: theme => theme.palette.blue,
                color:'#fff',
                p:'6rem 0'
            }}
        >
            <Container maxWidth='xl'>
                <Grid container columnSpacing={2} rowSpacing={8} alignItems='center'>
                    {pageData?.data.data.attributes.content &&
                        <Grid item xs={12}>
                            <Box
                                className='html-section'
                            >
                                {parse(pageData.data.data.attributes.content)}
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <Typography variant={mobile ? 'h3' : 'h2'} component='h2'
                            sx={{
                                color: theme => theme.palette.yellow,
                            }}
                        >
                            Cambridge
                        </Typography>
                        <Typography variant={mobile ? 'h5' : 'h4'} component='h4'
                            sx={{
                                mt: '2rem',
                                //fontWeight:'bold',
                            }}
                        >
                            Unit 5, New Barn Farm Offices<br />
                            Tadlow, Royston<br />
                            Cambridgeshire<br />
                            SG8 0EP
                        </Typography>
                        <Typography variant={mobile ? 'h5' : 'h4'} component='h4'
                            sx={{
                                mt: '4rem',
                                fontWeight: 'bold',
                                color: theme => theme.palette.yellow,
                                transition: '0.5s ease-in-out all',
                                '&:hover': {
                                    color: '#fff',
                                    transition: '0.5s ease-in-out all'
                                }
                            }}
                        >
                            <a 
                                href='tel:+441223398156'
                                aria-label="Contact us by phone"
                            >
                                +44(0) 1223 398 156
                            </a>
                        </Typography>
                        <Typography variant={mobile ? 'h5' : 'h4'} component='h4'
                            sx={{
                                mt: '0.5rem',
                                fontWeight: 'bold',
                                color: theme => theme.palette.yellow,
                                transition: '0.5s ease-in-out all',
                                '&:hover': {
                                    color: '#fff',
                                    transition: '0.5s ease-in-out all'
                                }
                            }}
                        >
                            <a 
                                href='mailto:hello@design-monkey.co.uk'
                                aria-label="Contact us by email"
                            >
                                hello@design-monkey.co.uk
                            </a>
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={12} md={6} container spacing={2}
                        sx={{
                            mt: mobile ? '4rem' : 0
                        }}
                    >
                        {success?
                            <Box
                                sx={{
                                    width:'100%',
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center'
                                }}
                            >
                                <MarkEmailReadIcon
                                    sx={{
                                        fontSize:'4rem',
                                        mb:'1rem',
                                        color:theme=>theme.palette.yellow
                                    }}
                                />
                                <Typography variant='h4' component='span'
                                    sx={{
                                        textAlign:'center'
                                    }}
                                >
                                    Thank you, we've got your enquiry and will be in touch to get things started!
                                </Typography>
                            </Box>
                        :
                            <ContactForm
                                setSuccess={setSuccess}
                            />
                        }
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        mt:'4rem',
                        display:'flex',
                        gap:'1rem'
                    }}
                >
                    <a 
                        href='https://www.facebook.com/DesignMonkeyMediaLtd/'
                        aria-label="View our facebook profile"
                        target='_blank'
                    >
                        <FacebookIcon fontSize='large' sx={{color:'#fff'}}/>
                    </a>
                    <a 
                        href='https://x.com/DesignMonkeyuk' 
                        aria-label="View our X profile"
                        target='_blank'
                    >
                        <XIcon fontSize='large' sx={{color:'#fff'}}/>
                    </a>
                    <a 
                        href='https://uk.linkedin.com/company/design-monkey-media-ltd' 
                        aria-label="View our LinkedIn profile"
                        target='_blank'
                    >
                        <LinkedInIcon fontSize='large' sx={{color:'#fff'}}/>
                    </a>
                    <a 
                        href='https://www.instagram.com/designmonkeymedia/' 
                        aria-label="View our Instagram profile"
                        target='_blank'
                    >
                        <InstagramIcon fontSize='large' sx={{color:'#fff'}}/>
                    </a>
                </Box>
            </Container>
        </Box>
    );
}

export default Contact;