import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleServiceHeader from "./SingleServiceHeader";
import SingleServiceIntro from "./SingleServiceIntro";
import ShowcasesList from "../showcases/ShowcasesList";
import SingleServiceContent from "./SingleServiceContent";
import { Box, Fade } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SingleServiceExpertise from "./SingleServiceExpertise";
import Quote from "../reusable/Quote";
import Keypoints from "../reusable/Keypoints";
import CTA from "../reusable/CTA";
import TabbedContent from "../reusable/TabbedContent";
import MoreServices from "./MoreServices";
import { MetaContext } from "../context/MetaContext"; 

function SingleService() {

    const { slug } = useParams();
    const navigate=useNavigate();
    const meta=useContext(MetaContext);

    //Set html background color
    useEffect(() => {
        document.body.style.backgroundColor='#01153C'
    },[]);

    //Fetch services data
    const { data: serviceData } = useQuery(({
        queryKey: ['service', slug],
        queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/services?filters[slug][$eq]=${slug}&populate=deep`),
        enable: !!slug
    }))

    //Fetch services data
    const { data: servicelist } = useQuery(({
        queryKey: ['service list'],
        queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/services?populate=deep`)
    }))

    const [service, setService]=useState();

    useEffect(()=>{
        //console.log(serviceData)
        if(serviceData){
            if(serviceData.data.data.length){
                setService(serviceData.data.data[0].attributes);
                meta.setTitle(serviceData.data.data[0].attributes.seo.metaTitle);
                meta.setDescription(serviceData.data.data[0].attributes.seo.metaDescription);
            }
            else{
                navigate('/404');
            }
        }
    },[serviceData]);

    //console.log(service)

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#fff'}}));
	},[]);

    return(
        <Box
            className="dm-section"
            data-color={'#FFF'}
        >
            {service?
                <Fade in={Boolean(service)}>
                    <Box>
                        <SingleServiceHeader service={service} />
                        <SingleServiceIntro service={service} />
                        {service.keypoints &&
                            <Keypoints keypoints={service.keypoints} key={'keypoints_'+service.keypoints.id}/>
                        }
                        {service.tabbed_content &&
                            <TabbedContent content={service.tabbed_content} key={'keypoints_'+service.tabbed_content.id}/>
                        }
                        {service.content_blocks.length &&
                            <SingleServiceContent service={service} />
                        }
                        <SingleServiceExpertise service={service}/>
                        {service.quote &&
                            <Quote quote={service.quote} key={'quote_'+service.quote.id} aboveShowcases/>
                        }
                        {service.related_showcases.data.length &&
                            <ShowcasesList showcases={service?.related_showcases?.data} singleServicePage/>
                        }
                        {service.call_to_action &&
                            <CTA cta={service.call_to_action}/>
                        }
                        {/* <ServicesList isSingle servicesData={servicelist?.data?.data} /> */}
                        <MoreServices services={servicelist?.data?.data}/>
                    </Box>
                </Fade>
            :
                <Box
                    sx={{
                        height:'100vh'
                    }}
                />
            }
        </Box>
    );
}

export default SingleService;