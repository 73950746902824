import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/system';
import HomeVideo from './HomeVideo';
import HomeIntro from './HomeIntro';
import axios from 'axios';
import HomeShowcase from './HomeShowcase';
import HomeFamily from './HomeFamily';
import HomeClients from './HomeClients';
import HomeInsights from './HomeInsights';
import HomeServices from './HomeServices';
import { MetaContext } from '../context/MetaContext';
import { useQuery } from '@tanstack/react-query';

function Home() {

	const meta = useContext(MetaContext);

	//Fetch homepage content
	const { data } = useQuery(({
		queryKey:['homepage'],
		queryFn: async () => {
			const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/homepage?populate=deep`);
			return response.data.data.attributes;
		}
	}));

	useEffect(() => {
		if (data) {
			meta.setTitle(data.seo.metaTitle);
			meta.setDescription(data.seo.metaDescription);
		}
	}, [data]);

	//set html background color
	useEffect(() => {
		document.body.style.backgroundColor = '#01153C'
	}, []);

	//Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#fff'}}));
	},[]);

	return(
		<>
			<Box
				className="dm-section"
				data-color="#fff"
				sx={{
					position: 'relative',
				}}
			>
				<HomeVideo data={data}/>
				{data &&
					<Box sx={{ position: 'relative', zIndex: 1 }}>
						<HomeIntro data={data} />
						<HomeServices data={data} />
					</Box>
				}
			</Box>
			{data &&
				<>
					<HomeShowcase data={data} showcase={data.showcase.data.attributes} />
					<HomeFamily data={data} />
					<HomeClients />
					<HomeInsights data={data} />
				</>
			}
		</>
	);
}

export default Home;
