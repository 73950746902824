import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Parallax } from "react-scroll-parallax";
import AnimatedText from "../reusable/AnimatedText";

function AboutIntro({ aboutData }) {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      className="dm-section"
      data-color={"#01153C"}
      sx={{
        backgroundColor: (theme) => theme.palette.yellow,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            p: mobile ? "6rem 1rem" : "8rem 0",
            color: (theme) => theme.palette.blue,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {aboutData?.heading ? (
                <AnimatedText
                  text={aboutData.heading}
                  variant="h1"
                  component="h1"
                  sx={{
                    maxWidth: "500px",
                    p: mobile ? "1rem 0" : "4rem 0",
                    fontSize: {
                      xs: "4rem",
                      md: "6rem",
                    },
                    color: (theme) => theme.palette.blue,
                  }}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <Parallax speed={5}>
                <img
                  src={
                    process.env.REACT_APP_BACKEND +
                    aboutData?.media.data[0].attributes.url
                  }
                  alt={aboutData?.media.data[0].attributes.alternativeText}
                  width="100%"
                />
              </Parallax>
            </Grid>
            <Grid item xs={12} md={6}>
              <Parallax speed={0}>
                <img
                  src={
                    process.env.REACT_APP_BACKEND +
                    aboutData?.media.data[2].attributes.url
                  }
                  alt={aboutData?.media.data[2].attributes.alternativeText}
                  width="100%"
                />
              </Parallax>
            </Grid>
            <Grid item xs={12} md={6} sx={{ position: "relative" }}>
              <Parallax speed={10}>
                <img
                  src={
                    process.env.REACT_APP_BACKEND +
                    aboutData?.media.data[1].attributes.url
                  }
                  alt={aboutData?.media.data[1].attributes.alternativeText}
                  width={mobile ? "100%" : "75%"}
                  style={{
                    position: mobile ? "static" : "absolute",
                    left: "-20%",
                    top: "-30%",
                    transform: mobile ? "" : "translate(-20%,-30%)",
                  }}
                />
              </Parallax>
            </Grid>
            <Grid item xs={12} sx={{ mt: mobile ? "2rem" : "8rem" }}>
              <Typography
                variant={mobile ? "h4" : "h3"}
                component="h3"
                sx={{
                  color: (theme) => theme.palette.primary.contrast,
                  width: mobile ? "100%" : "75%",
                  ml: "auto",
                  fontWeight: "bold",
                }}
              >
                {aboutData?.paragraph}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default AboutIntro;
