import { Box, Fade } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SingleShowcaseHero from "./SingleShowcaseHero";
import SingleShowcaseImages from "./SingleShowcaseImages";
import MoreShowcases from "./MoreShowcases";
import { useQuery } from "@tanstack/react-query";
import SingleShowcaseContent from "./SingleShowcaseContent";
import Quote from "../reusable/Quote";
import Keypoints from "../reusable/Keypoints";
import CTA from "../reusable/CTA";
import { MetaContext } from "../context/MetaContext"; 

function SingleShowcase() {

    const { slug } = useParams();
    const navigate=useNavigate();
    const meta=useContext(MetaContext);

    //Set html background color
    useEffect(() => {
        document.body.style.backgroundColor = '#FFF'
    }, []);

    //Fetch showcase data
    const { data: showcaseData } = useQuery(({
        queryKey: ['showcases', slug],
        queryFn: () => axios.get(process.env.REACT_APP_BACKEND + '/api/showcases?filters[slug][$eq]=' + slug + '&populate=deep'),
        enabled: !!slug
    }));

    const [showcase, setShowcase]=useState();

    useEffect(()=>{
        //console.log(showcaseData)
        if(showcaseData){
            if(showcaseData.data.data.length){
                setShowcase(showcaseData.data.data[0].attributes);
                meta.setTitle(showcaseData.data.data[0].attributes.seo.metaTitle);
                meta.setDescription(showcaseData.data.data[0].attributes.seo.metaDescription);
            }
            else{
                navigate('/404');
            }
        }
    },[showcaseData]);

    //console.log(showcase)

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#fff'}}));
	},[]);

    return(
        <>
            {showcase?
                <Fade in={Boolean(showcase)}>
                    <Box>
                        <SingleShowcaseHero showcase={showcase}/>
                        {showcase.content1 &&
                            <SingleShowcaseContent content={showcase.content1} key={'content1_'+showcase.content1.id}/>
                        }
                        {showcase.keypoints &&
                            <Keypoints keypoints={showcase.keypoints} key={'keypoints_'+showcase.keypoints.id}/>
                        }
                        <SingleShowcaseImages showcase={showcase}/>
                        {showcase.content2 &&
                            <SingleShowcaseContent content={showcase.content2} key={'content2_'+showcase.content2.id} underImages/>
                        }
                        {showcase.quote &&
                            <Quote quote={showcase.quote} key={'quote_'+showcase.quote.id}/>
                        }
                        {showcase.call_to_action &&
                            <CTA cta={showcase.call_to_action} whiteBG/>
                        }
                        <MoreShowcases showcase={showcase}/>
                    </Box>
                </Fade>
            :
                <Box
                    sx={{
                        height:'100vh'
                    }}
                />
            }
        </>
    );
}

export default SingleShowcase;