import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import ShowcasesIntro from './ShowcasesIntro';
import ShowcasesList from './ShowcasesList';
import { Box, Fade } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { MetaContext } from '../context/MetaContext';

function Showcases() {

	const meta=useContext(MetaContext);

	//Set html background color
	useEffect(() => {
		document.body.style.backgroundColor = '#01153C'
	}, []);
	
	//Fetch showcase content
	const { data: pageData } = useQuery(({
		queryKey: ['page','showcases'],//must match the menu slug
		queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/showcases-page?populate=deep`)
	}));

	useEffect(()=>{
		//console.log(pageData)
		if(pageData){
			meta.setTitle(pageData.data.data.attributes.seo.metaTitle);
			meta.setDescription(pageData.data.data.attributes.seo.metaDescription);
		}
	},[pageData]);

	//Fetch showcase data
	const { data: showcases } = useQuery(({
		queryKey: ['showcases'],
		queryFn: () => axios.get(`${process.env.REACT_APP_BACKEND}/api/showcases?populate=deep`)
	}));

	//Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#fff'}}));
	},[]);

	return(
		<>
			{pageData?.data?.data?.attributes ?
				<Fade in={Boolean(pageData?.data?.data?.attributes)}>
					<Box>
						<ShowcasesIntro pageData={pageData?.data?.data?.attributes} />
						<ShowcasesList showcases={showcases?.data?.data} /> 
					</Box>
				</Fade>
			:
				<Box
					data-color={'#01153C'}
					sx={{
						height:'100vh',
					}}
				/>
			}
		</>
	);
}

export default Showcases;