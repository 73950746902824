import "./App.css";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { ParallaxProvider } from "react-scroll-parallax";
import { MetaProvider } from "./context/MetaContext";
import Cursor from "./reusable/Cursor";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {

	const theme = createTheme({
		palette: {
			blue: "#01153C",
			yellow: "#FFCD00",
			white: "#FFFFFF",
		},
		typography: {
			fontFamily: "din-2014",
			h1: {
				fontSize: "8rem",
				fontWeight: "bold",
			},
			h2: {
				fontSize: "5rem",
				lineHeight: "5rem",
				fontWeight: "bold",
			},
			h3: {
				fontSize: "2.6rem",
				fontWeight: "bold",
			},
			h4: {
				fontSize: "2rem",
				fontWeight:'bold'
			},
			h5: {
				fontSize: "1.6rem",
				fontWeight: "bold",
			},
			h6: {
				fontSize: "1.4rem",
			},
			p: {
				fontSize: "1.2rem",
			},
		},
		components: {
			MuiLink: {
				defaultProps: {
					underline: "none",
				},
			},
		},
	});

	return(
		<MetaProvider>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<ParallaxProvider>
						<BrowserRouter>
							<MainRoutes />
						</BrowserRouter>
					</ParallaxProvider>
				</QueryClientProvider>
				<Cursor />
			</ThemeProvider>
		</MetaProvider>
	);
}

export default App;
