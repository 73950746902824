import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';

export const MetaContext = createContext();

export function MetaProvider(props) {

    const [head, setHead]=useState();
    const [title, setTitle]=useState('');
    const [description, setDescription]=useState('');

    //Fetch head
    useEffect(()=>{
        (async()=>{
            try{
                const response = await axios.get(process.env.REACT_APP_BACKEND+'/api/head');
                setHead(response.data.data.attributes.html);
            }
            catch(error){
                console.error(error);
            }
        })();
    },[]);

    return(
        <MetaContext.Provider 
            value={{ 
                setTitle,
                setDescription
            }}
        >
            <Helmet>
                <meta charset="utf-8" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />
                {head && parse(head)}
                <title>{title}</title>
                <meta name='description' content={description}/>
            </Helmet>
            {props.children}
        </MetaContext.Provider>
    );
};