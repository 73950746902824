import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useVisible } from "react-hooks-visible";
import AboutModal from "./AboutModal";
import AnimatedText from "../reusable/AnimatedText";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function TeamMember({ member, i, delay = 0 }) {
  //console.log(member)

  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [ref, visible] = useVisible((vi) => vi > 0.9);
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Fire fade in animation only once
  useEffect(() => {
    if (visible && !display) {
      setDisplay(true);
    }
  }, [visible]);

  //Member name  Animation
  const [displayName, setDisplayName] = useState(false);
  useEffect(() => {
    if (visible && !displayName) {
      setDisplayName(true);
    }
  }, [visible]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          opacity: display ? 1 : 0,
          translate: display ? "0" : "0 10vh",
          transition: "opacity 0.5s, translate 0.5s",
          transitionDelay: `${delay * 0.2}s`,
          "&:hover .MuiSvgIcon-root": {
            opacity: "1",
            marginLeft: "1rem",
          },
          cursor: "pointer !important",
        }}
        onClick={handleOpen}
      >
        <Box
          sx={{
            height: mobile ? 250 : 600,
            overflow: "hidden",
            width: "100%",
            "& > img": {
              transition: "transform 0.35s 0s",
              transform: "scale(1)",
            },
            "&:hover img": {
              transform: "scale(1.05)",
            },
          }}
        >
          <img
            src={
              process.env.REACT_APP_BACKEND + member.photo.data.attributes.url
            }
            alt={member.photo.data.attributes.alternativeText}
            width="100%"
            height={mobile ? 250 : 600}
            style={{ objectFit: "cover" }}
            draggable={false}
          />
        </Box>
        {/* <AnimatedText
          text={member.bio}
          variant="body1"
          component="p"
          sx={{ mt: "1rem" }}
        /> */}
        <Grid container justifyContent="center" alignItems="left">
          <Grid item xs={12}>
            <Typography
              variant={mobile ? "p" : "h5"}
              component="h5"
              sx={{
                mt: mobile ? "0" : "1rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                transition: "color 0.25s",
              }}
            >
              {member.name}
              <ArrowForwardIcon
                fontSize="large"
                sx={{
                  opacity: "0",
                  transition: "opacity 0.25s, margin-left 0.25s",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Typography variant={mobile ? "body1" : "h6"} component="h6">
          {member.role}
        </Typography>
      </Box>
      <AboutModal member={member} i={i} open={open} setOpen={setOpen} />
    </>
  );
}

export default TeamMember;
