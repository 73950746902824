import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import ServicesHeader from './ServicesHeader';
import ServicesFAQ from './ServicesFAQ';
import ServicesIntro from './ServicesIntro';
import ServicesList from './ServicesList';
import { useQuery } from '@tanstack/react-query';
import { Box, Fade } from '@mui/material';
import { MetaContext } from '../context/MetaContext';

function Services(){

	const meta=useContext(MetaContext);

    //set html background color
    useEffect(() => {
        document.body.style.backgroundColor = '#FFF'
    },[]);

    //Fetch service content
    const { data: pageData } = useQuery(({
        queryKey: ['page','services'],//must match the menu slug
        queryFn: async () => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/services-page?populate=deep`);
            return response.data.data.attributes;
        }
    }));

    useEffect(()=>{
		//console.log(pageData)
		if(pageData){
            meta.setTitle(pageData.seo.metaTitle);
            meta.setDescription(pageData.seo.metaDescription);
		}
	},[pageData]);

    //Fetch services data
    const { data: servicesData } = useQuery(({
        queryKey: ['services'],
        queryFn: async () => {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/services?populate=deep`)
            return response.data.data;
        }
    }));

    //Set header colour
	useEffect(()=>{
		window.dispatchEvent(new CustomEvent('headerColorChange',{detail:{color:'#01153C'}}));
	},[]);

    return(
        <>
            {pageData?
                <Fade in={Boolean(pageData)}>
                    <Box>
                        <ServicesHeader pageData={pageData} />
                        <ServicesIntro pageData={pageData} />
                        {servicesData && 
                            <ServicesList servicesData={servicesData} />
                        }
                        <ServicesFAQ pageData={pageData} />
                    </Box>
                </Fade>
            :
                <Box
                    data-color={'#01153C'}
                    sx={{
                        height:'100vh'
                    }}
                />
            }
        </>
    );
}

export default Services;